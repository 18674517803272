import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Coins, Dice5, Users } from 'lucide-react';
import api from '../services/api';

const JoinGame = () => {
  const { gameId } = useParams();
  const [paletteNumber, setPaletteNumber] = useState('');
  const [error, setError] = useState('');
  const [gameDetails, setGameDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await api.get(`/game/${gameId}`);
        setGameDetails(response.data);
      } catch (err) {
        setError('Failed to fetch game details');
      }
    };

    fetchGameDetails();
  }, [gameId]);

  const handleJoinGame = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await api.post(`/game/${gameId}/join`, { paletteNumber: parseInt(paletteNumber) });
      if (response.data.message === 'Successfully joined the game') {
        navigate(`/game/${gameId}/player`, { state: { paletteNumber: parseInt(paletteNumber) } });
      } else {
        setError('Failed to join the game. Please try again.');
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message);
      } else {
        setError('Error joining game. Please check your palette number and try again.');
      }
    }
  };

  if (!gameDetails) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-purple-900 to-indigo-900">
        <div className="text-white text-2xl">Loading game details...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 to-indigo-900 text-white flex items-center justify-center p-4">
      <div className="bg-gradient-to-r from-gray-900 to-gray-800 shadow-lg rounded-lg p-8 border-2 border-yellow-500 max-w-md w-full">
        <h2 className="text-4xl font-bold mb-6 text-center text-yellow-400 font-serif">Join Bingo Game</h2>
        <div className="mb-4 flex items-center">
          <Dice5 className="mr-2 text-yellow-400" />
          <h3 className="text-2xl font-semibold text-yellow-400">Game ID: {gameId}</h3>
        </div>
        <div className="mb-4 flex items-center">
          <Coins className="mr-2 text-yellow-400" />
          <p><span className="font-semibold">Betting Amount:</span> ${gameDetails.bettingAmount}</p>
        </div>
        <div className="mb-4 flex items-center">
          <Users className="mr-2 text-yellow-400" />
          <p>
            <span className="font-semibold">Game Status:</span> 
            <span className={`ml-2 px-2 py-1 rounded ${
              gameDetails.status === 'waiting' ? 'bg-yellow-600 text-white' :
              gameDetails.status === 'in-progress' ? 'bg-green-600 text-white' :
              'bg-red-600 text-white'
            }`}>
              {gameDetails.status}
            </span>
          </p>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {gameDetails.status === 'waiting' ? (
          <form onSubmit={handleJoinGame} className="mt-6">
            <div className="mb-4">
              <label htmlFor="paletteNumber" className="block text-sm font-medium text-gray-300 mb-2">
                Choose Palette Number:
              </label>
              <input
                type="number"
                id="paletteNumber"
                value={paletteNumber}
                onChange={(e) => setPaletteNumber(e.target.value)}
                min="1"
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>
            <button 
              type="submit"
              className="w-full bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
            >
              Join Game
            </button>
          </form>
        ) : (
          <p className="text-center text-red-400 mt-4">This game is no longer accepting new players.</p>
        )}
      </div>
    </div>
  );
};

export default JoinGame;