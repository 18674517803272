import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { Clock, Coins, Dice5, Users, Play, Share2, X } from 'lucide-react';
import api from '../services/api';

const GameLobby = () => {
  const { gameId } = useParams();
  const [gameDetails, setGameDetails] = useState(null);
  const [error, setError] = useState('');
  const [joinedPlayers, setJoinedPlayers] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await api.get(`/game/${gameId}`);
        console.log("API response:", response.data); // Log the entire response
        setGameDetails(response.data);
        setJoinedPlayers(response.data.players || []);
        if (response.data.scheduledStartTime) {
          console.log("Scheduled start time:", response.data.scheduledStartTime); // Log the scheduled start time
          const startTime = new Date(response.data.scheduledStartTime).getTime();
          const now = new Date().getTime();
          const remainingTime = Math.max(0, Math.floor((startTime - now) / 1000));
          console.log("Calculated countdown:", remainingTime); // Log the calculated countdown
          setCountdown(remainingTime);
        } else {
          console.log("No scheduledStartTime found in the response");
        }
      } catch (err) {
        console.error("Error fetching game details:", err);
        setError('Failed to fetch game details');
      }
    };

    fetchGameDetails();
    const intervalId = setInterval(fetchGameDetails, 5000);
    return () => clearInterval(intervalId);
  }, [gameId]);

  useEffect(() => {
    let timer;
    if (countdown !== null && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            navigate(`/game/${gameId}/host`);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown, gameId, navigate]);

  const handleStartGame = async () => {
    try {
      const response = await api.post(`/game/${gameId}/start`);
      if (response.data.message === 'Game started successfully') {
        navigate(`/game/${gameId}/host`);
      } else {
        setError(response.data.message || 'Failed to start the game');
      }
    } catch (err) {
      setError('Error starting the game. Please try again.');
    }
  };

  const handleRemovePlayer = async (paletteNumber) => {
    try {
      const response = await api.post(`/game/${gameId}/remove-player`, { paletteNumber });
      if (response.data.message === 'Player removed successfully') {
        setJoinedPlayers(joinedPlayers.filter(player => player.paletteNumber !== paletteNumber));
      } else {
        setError(response.data.message || 'Failed to remove player');
      }
    } catch (err) {
      setError('Error removing player. Please try again.');
    }
  };

  const qrValue = `${window.location.origin}/join/${gameId}`;

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-indigo-900 to-gray-900 text-white p-4 sm:p-6 lg:p-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-5xl font-bold mb-8 text-center text-yellow-400 font-serif animate-pulse">VIP Power Lobby</h2>
        {error && (
          <div className="bg-red-800 border-2 border-red-600 text-white px-6 py-4 rounded-lg mb-6 shadow-lg" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline ml-2">{error}</span>
          </div>
        )}
        {gameDetails && (
          <div className="bg-gradient-to-r from-gray-900 to-gray-800 shadow-2xl rounded-lg p-6 border-4 border-yellow-500 transition-all duration-300 hover:shadow-yellow-500/20">
            <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-6 lg:space-y-0 lg:space-x-8">
              <div className="w-full lg:w-2/3">
                <h3 className="text-4xl font-semibold mb-6 text-yellow-400 flex items-center">
                  <Dice5 className="mr-3" size={32} />
                  Game ID: {gameId}
                </h3>
                <div className="flex items-center mb-4 text-lg">
                  <Coins className="mr-3 text-yellow-400" size={24} />
                  <p><span className="font-semibold">Betting Amount:</span> {gameDetails.bettingAmount} Birr</p>
                </div>
                <div className="mb-6 flex items-center text-lg">
                  <Share2 className="mr-3 text-yellow-400" size={24} />
                  <span className="font-semibold mr-2">Status:</span> 
                  <span className={`px-3 py-1 rounded-full ${
                    gameDetails.status === 'waiting' ? 'bg-yellow-600 text-white' :
                    gameDetails.status === 'in-progress' ? 'bg-green-600 text-white' :
                    'bg-red-600 text-white'
                  }`}>
                    {gameDetails.status.charAt(0).toUpperCase() + gameDetails.status.slice(1)}
                  </span>
                </div>
                {countdown !== null && (
                  <div className="mb-6 flex items-center text-lg">
                    <Clock className="mr-3 text-yellow-400" size={24} />
                    <span className="font-semibold mr-2">Game starts in:</span> 
                    <span className="text-2xl font-bold text-yellow-400">
                      {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center w-full lg:w-1/3">
                <h4 className="text-2xl font-semibold mb-4 text-yellow-400">Scan to Join</h4>
                <div className="bg-white p-4 rounded-lg shadow-lg inline-block transition-all duration-300 hover:shadow-yellow-500/50">
                  <QRCodeSVG value={qrValue} size={175} />
                </div>
                <p className="mt-4 text-sm text-center text-yellow-400">Or use this link:</p>
                <p className="bg-gray-800 p-3 rounded-lg text-sm break-all text-center text-yellow-400 mt-2 w-full">{qrValue}</p>
              </div>
            </div>
            <div>
            <h4 className="text-2xl font-semibold mb-4 text-yellow-400 flex items-center">
                  <Users className="mr-3" size={24} />
                  Players at the Table
                </h4>
                {joinedPlayers.length > 0 ? (
                <ul className="bg-gray-800 rounded-lg p-4 space-y-2 max-h-60 overflow-y-auto scrollbar-thin scrollbar-thumb-yellow-500 scrollbar-track-gray-700">
                  {joinedPlayers.map((player, index) => (
                    <li key={index} className="flex items-center justify-between text-lg transition-all duration-200 hover:bg-gray-700 p-2 rounded">
                      <div className="flex items-center">
                        <Coins className="mr-3 text-yellow-400" size={20} />
                        Palette Number: {player.paletteNumber}
                      </div>
                      <button
                        onClick={() => handleRemovePlayer(player.paletteNumber)}
                        className="bg-red-600 hover:bg-red-700 text-white p-2 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        <X size={16} />
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-400 italic text-lg">No players have joined yet.</p>
              )}
                {gameDetails.status === 'waiting' && (
                  <button 
                    onClick={handleStartGame} 
                    disabled={joinedPlayers.length === 0}
                    className={`mt-6  px-8 py-4 rounded-full font-bold text-xl flex items-center justify-center w-full sm:w-auto ${
                      joinedPlayers.length === 0
                        ? 'bg-gray-600 cursor-not-allowed'
                        : 'bg-green-600 hover:bg-green-700 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg'
                    }`}
                  >
                    <Play className="mr-2 align-center" size={24} />
                    Start the Game
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameLobby;