import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DollarSign, Clock, Play } from 'lucide-react';
import api from '../services/api';

const CreateGame = () => {
  const [bettingAmount, setBettingAmount] = useState('');
  const [callSpeed, setCallSpeed] = useState('5000');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const lastBetAmount = localStorage.getItem('lastBetAmount');
    if (lastBetAmount) {
      setBettingAmount(lastBetAmount);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/game/create', {
        bettingAmount: parseFloat(bettingAmount),
        callSpeed: parseInt(callSpeed)
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Save the betting amount to local storage
      localStorage.setItem('lastBetAmount', bettingAmount);

      navigate(`/game-lobby/${response.data.gameId}`, { 
        state: { 
          bettingAmount, 
          callSpeed 
        } 
      });
    } catch (err) {
      setError('Failed to create game. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 to-indigo-900 flex flex-col py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-4xl font-extrabold text-yellow-400">
          Create New Game
        </h2>
        <p className="mt-2 text-center text-sm text-gray-400">
          Set up your high-stakes Bingo game
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 py-8 px-4 shadow-lg sm:rounded-lg sm:px-10 border-2 border-yellow-500">
          {error && (
            <div className="mb-4 bg-red-900 border border-red-700 text-white px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="bettingAmount" className="block text-sm font-medium text-yellow-400">
                Betting Amount ($)
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  id="bettingAmount"
                  value={bettingAmount}
                  onChange={(e) => setBettingAmount(e.target.value)}
                  min="0"
                  step="0.01"
                  required
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-full shadow-sm text-lg font-medium text-gray-900 bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition duration-300 transform hover:scale-105"
              >
                <Play className="mt-1 mr-2 h-5 w-5" /> Start the Game
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateGame;