import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Coins, Dice5, Play, Pause, StopCircle, Plus } from 'lucide-react';
import api from '../services/api';
import CreateGame from './CreateGame'; // Import the CreateGame component

const HostGame = () => {
  const { gameId } = useParams();
  const [gameDetails, setGameDetails] = useState(null);
  const [error, setError] = useState('');
  const [calledNumbers, setCalledNumbers] = useState([]);
  const [displayedNumbers, setDisplayedNumbers] = useState([]);
  const [lastCalledNumber, setLastCalledNumber] = useState(null);
  const [bingoClaimPending, setBingoClaimPending] = useState(false);
  const [claimingPlayer, setClaimingPlayer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await api.get(`/game/${gameId}`);
        setGameDetails(response.data);
        if (response.data.calledNumbers) {
          setCalledNumbers(response.data.calledNumbers);
        }
        setDisplayedNumbers(response.data.displayedNumbers || []);
        setLastCalledNumber(response.data.displayedNumbers ? response.data.displayedNumbers[response.data.displayedNumbers.length - 1] : null);
        
        if (response.data.bingoClaim && response.data.bingoClaim.status === 'pending') {
          setBingoClaimPending(true);
          setClaimingPlayer(response.data.bingoClaim.paletteNumber);
        } else {
          setBingoClaimPending(false);
          setClaimingPlayer(null);
        }
      } catch (err) {
        setError('Failed to fetch game details');
      }
    };

    fetchGameDetails();
    const intervalId = setInterval(fetchGameDetails, 1000);
    return () => clearInterval(intervalId);
  }, [gameId]);

  const handleToggleGameState = async () => {
    try {
      await api.post(`/game/${gameId}/toggle-state`);
      const response = await api.get(`/game/${gameId}`);
      setGameDetails(response.data);
    } catch (err) {
      setError('Failed to toggle game state');
    }
  };

  const handleEndGame = async () => {
    try {
      await api.post(`/game/${gameId}/end`);
      // Fetch updated game details after ending the game
      const response = await api.get(`/game/${gameId}`);
      setGameDetails(response.data);
    } catch (err) {
      console.log(err);
      setError('Failed to end game');
    }
  };

  if (!gameDetails) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-purple-900 to-indigo-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  // Render CreateGame component if game status is finished
  if (gameDetails.status === 'finished') {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-900 to-indigo-900 text-white p-4">
        <div className="max-w-4xl mx-auto">
          <div className="bg-gradient-to-r from-gray-900 to-gray-800 shadow-lg rounded-lg p-6 mb-6 border-2 border-yellow-500">
            <h2 className="text-4xl font-bold mb-2 text-yellow-400">Previous Game: {gameId}</h2>
            <h3 className="text-3xl font-bold mb-4 text-green-400 flex items-center">
              <Coins className="mr-2" />
              Winner Card: {gameDetails.winnerCard}
            </h3>
          </div>
          <CreateGame />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 to-indigo-900 text-white p-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 shadow-lg rounded-lg p-6 mb-6 border-2 border-yellow-500">
          <h2 className="text-4xl font-bold mb-2 text-yellow-400">Bingo Game: {gameId}</h2>
          <p className="text-2xl mb-4 flex items-center">
            <Dice5 className="mr-2 text-yellow-400" />
            Game Status: 
            <span className={`font-semibold ml-2 ${
              gameDetails.status === 'in-progress' ? 'text-green-400' :
              gameDetails.status === 'paused' ? 'text-yellow-400' :
              'text-red-400'
            }`}>
              {gameDetails.status.charAt(0).toUpperCase() + gameDetails.status.slice(1)}
            </span>
          </p>
        </div>

        <div className="bg-gradient-to-r from-gray-900 to-gray-800 shadow-lg rounded-lg p-6 border-2 border-yellow-500">
          <h3 className="text-2xl font-semibold mb-4 text-yellow-400">Game Controls</h3>
          <div className="flex flex-wrap gap-4">
            {gameDetails.status === 'in-progress' && (
              <button 
                onClick={handleToggleGameState}
                className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-3 px-6 rounded-full flex items-center transition duration-300 transform hover:scale-105"
              >
                <Pause className="mr-2" /> Pause Game
              </button>
            )}
            {gameDetails.status === 'paused' && (
              <button 
                onClick={handleToggleGameState}
                className="bg-green-500 hover:bg-green-600 text-gray-900 font-bold py-3 px-6 rounded-full flex items-center transition duration-300 transform hover:scale-105"
              >
                <Play className="mr-2" /> Resume Game
              </button>
            )}
            <button 
              onClick={handleEndGame}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-6 rounded-full flex items-center transition duration-300 transform hover:scale-105"
            >
              <StopCircle className="mr-2" /> End Game
            </button>
          </div>
        </div>

        {error && (
          <div className="mt-6 bg-red-900 border-2 border-red-500 text-white px-4 py-3 rounded-lg" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HostGame;