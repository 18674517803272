import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Clock, Coins, Dice5, Share2, QrCode, Volume2, VolumeX } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QRCodeSVG } from 'qrcode.react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import localforage from 'localforage';
import api from '../services/api';

const PlayerGame = () => {
  const { gameId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { audioLanguage } = location.state || {};
  const [gameDetails, setGameDetails] = useState(null);
  const [cardNumbers, setCardNumbers] = useState(null);
  const [newGameID, setNewGameID] = useState(null)
  const [calledNumbers, setCalledNumbers] = useState([]);
  const [displayedNumbers, setDisplayedNumbers] = useState([]);
  const [lastCalledNumber, setLastCalledNumber] = useState(null);
  const [error, setError] = useState('');
  const [audioFilesLoaded, setAudioFilesLoaded] = useState(false);
  const [winner, setWinner] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [winnerCardNumbers, setWinnerCardNumbers] = useState(null);
  const [clickedNumbers, setClickedNumbers] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const paletteNumber = location.state?.paletteNumber;
  const audioRef = useRef(null);
  const currentIndexRef = useRef(0);
  const previousGameStatusRef = useRef(null);
  const scannerRef = useRef(null);
  const [latestGame, setLatestGame] = useState(null);
  const [showPaletteModal, setShowPaletteModal] = useState(false);
  const [newPaletteNumber, setNewPaletteNumber] = useState('');
  const [winningAmount, setWinningAmount] = useState(0);

  useEffect(() => {
    const fetchAndStoreAudioFiles = async () => {
      setIsDownloading(true);
      for (let i = 1; i <= 75; i++) {
        let letter = '';
        if (i >= 1 && i <= 15) letter = 'B';
        else if (i >= 16 && i <= 30) letter = 'I';
        else if (i >= 31 && i <= 45) letter = 'N';
        else if (i >= 46 && i <= 60) letter = 'G';
        else if (i >= 61 && i <= 75) letter = 'O';
        
        const number = letter + i;
        const audioKey = `audio-male-${number}`;
        const storedAudioFile = await localforage.getItem(audioKey);
        if (!storedAudioFile) {
          const audioSrc = require(`../Assets/Audio/male/${number}.aac`);
          const response = await fetch(audioSrc);
          const arrayBuffer = await response.arrayBuffer();
          await localforage.setItem(audioKey, arrayBuffer);     
        }
      }
      setIsDownloading(false);
      setAudioFilesLoaded(true);
    };
  
    fetchAndStoreAudioFiles();
  }, [audioLanguage]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audioRef.current.muted = !isMuted;
  };

  useEffect(() => {
    if (gameDetails && gameDetails.status === 'finished') {
      fetchLatestGameByAdmin();
    }
  }, [gameDetails]);

  useEffect(() => {
    console.log('Current state:', {
      gameDetails,
      cardNumbers,
      audioFilesLoaded,
      error
    });
  }, [gameDetails, cardNumbers, audioFilesLoaded, error]);


  const fetchLatestGameByAdmin = async () => {
    try {
      const response = await api.get(`/game/${gameId}/latest-by-admin`);
      console.log(response.data)
      if (response.data && response.data.gameId !== gameId) {
        setNewGameID(response.data.gameId)
        setLatestGame(response.data);
      }
    } catch (err) {
      console.error('Failed to fetch latest game by admin:', err);
    }
  };

  const handleJoinLatestGame = async (e) => {
    e.preventDefault();
    setShowPaletteModal(true);
  };

  const handlePaletteSubmit = async (e) => {
    e.preventDefault();
    if (latestGame) {
      try {
        const response = await api.post(`/game/${newGameID}/join`, { paletteNumber: parseInt(newPaletteNumber) });
        if (response.data.message === 'Successfully joined the game') {
          // Reset all game-related state
          setGameDetails(null);
          setCardNumbers(null);
          setCalledNumbers([]);
          setDisplayedNumbers([]);
          setLastCalledNumber(null);
          setWinner(null);
          setWinnerCardNumbers(null);
          setClickedNumbers([]);
          
          // Navigate to the new game
          navigate(`/game/${newGameID}/player`, { 
            state: { 
              paletteNumber: parseInt(newPaletteNumber),
              audioLanguage 
            },
            replace: true // This will replace the current entry in the history stack
          });
        } else {
          setError('Failed to join the game. Please try again.');
        }
      } catch (err) {
        if (err.response && err.response.data) {
          setError(err.response.data.message);
        } else {
          setError('Error joining game. Please check your palette number and try again.');
        }
      }
    }
    setShowPaletteModal(false);
  };


  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        console.log('Fetching game details for gameId:', gameId);
        const response = await api.get(`/game/${gameId}`);
        console.log('Game details response:', response.data);
        setGameDetails(response.data);
        if (response.data.calledNumbers) {
          setCalledNumbers(response.data.calledNumbers);
        }
        if (response.data.scheduledStartTime) {
          const startTime = new Date(response.data.scheduledStartTime).getTime();
          const now = new Date().getTime();
          const remainingTime = Math.max(0, Math.floor((startTime - now) / 1000));
          setCountdown(remainingTime);
        }
        if (response.data.players && response.data.bettingAmount) {
          const amount = response.data.players.length * response.data.bettingAmount * 0.8;
          setWinningAmount(amount);
        }
      } catch (err) {
        console.error('Error fetching game details:', err);
        setError('Failed to fetch game details: ' + (err.response?.data?.message || err.message));
      }
    };

    const fetchCardNumbers = async () => {
      try {
        console.log('Fetching card numbers for paletteNumber:', paletteNumber);
        const response = await api.get(`/cards/${paletteNumber}`);
        console.log('Card numbers response:', response.data);
        setCardNumbers(response.data.numbers);
      } catch (err) {
        console.error('Error fetching card numbers:', err);
        setError('Failed to fetch card numbers: ' + (err.response?.data?.message || err.message));
      }
    };

    fetchGameDetails();
    fetchCardNumbers();
    const intervalId = setInterval(fetchGameDetails, 1000);
    return () => clearInterval(intervalId);
  }, [gameId, paletteNumber]);


  useEffect(() => {
    let timer;
    if (countdown !== null && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const playNextNumber = () => {
    if (currentIndexRef.current < calledNumbers.length && gameDetails.status === 'in-progress') {
      const newNumber = calledNumbers[currentIndexRef.current];
      setDisplayedNumbers(prev => [...prev, newNumber]);
      setLastCalledNumber(newNumber);

      localforage.getItem(`audio-male-${newNumber}`).then((arrayBuffer) => {
        const audioBlob = new Blob([arrayBuffer], { type: 'audio/aac' });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioRef.current.src = audioUrl;
        audioRef.current.load();
        audioRef.current.play();
      });

      currentIndexRef.current++;
    }
  };

  const fetchWinnerCardNumbers = async (winnerPaletteNumber) => {
    try {
      const response = await api.get(`/cards/${winnerPaletteNumber}`);
      setWinnerCardNumbers(response.data.numbers);
    } catch (err) {
      setError('Failed to fetch winner card numbers');
    }
  };

  useEffect(() => {
    if (gameDetails && gameDetails.status === 'finished' && gameDetails.winnerCard) {
      fetchWinnerCardNumbers(gameDetails.winnerCard);
    }
  }, [gameDetails]);
  
  useEffect(() => {
    if (!gameDetails || calledNumbers.length === 0 || !audioFilesLoaded) return;

    const handleAudioEnded = () => {
      if (gameDetails.status === 'in-progress') {
        playNextNumber();
      }
    };

    audioRef.current?.addEventListener('ended', handleAudioEnded);

    if (previousGameStatusRef.current === 'paused' && gameDetails.status === 'in-progress') {
      playNextNumber();
    }

    previousGameStatusRef.current = gameDetails.status;

    if (currentIndexRef.current === 0 && gameDetails.status === 'in-progress') {
      playNextNumber();
    }

    return () => {
      audioRef.current?.removeEventListener('ended', handleAudioEnded);
    };
  }, [gameDetails, calledNumbers, audioFilesLoaded]);

  const handleClaimBingo = async () => {
    try {
      const verifyResponse = await api.post(`/game/${gameId}/verify-winner`, {
        paletteNumber,
        calledNumbers: displayedNumbers
      });

      if (verifyResponse.data.isWinner) {
        toast.success('Valid Bingo! You are the winner!', {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error('Invalid Bingo claim. Please check your card.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (err) {
      setError('Failed to submit or verify bingo claim');
      toast.error('Failed to submit or verify bingo claim. Please try again.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    if (showScanner) {
      scannerRef.current = new Html5QrcodeScanner('reader', { fps: 10, qrbox: 250 });
      scannerRef.current.render(handleQRCodeDetected, handleScanError);
    } else if (scannerRef.current) {
      scannerRef.current.clear();
    }
    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear();
      }
    };
  }, [showScanner]);

  const handleScanNewGame = () => {
    setShowScanner(true);
  };

  const handleQRCodeDetected = (decodedText) => {
    setShowScanner(false);
    if (decodedText.startsWith('http') || decodedText.startsWith('https')) {
      window.location.href = decodedText;
    } else {
      navigate(`/game/${decodedText}`, { state: { paletteNumber, audioLanguage } });
    }
  };

  const handleScanError = (err) => {
    console.error(err);
  };

  const handleShareGame = () => {
    setShowQR(true);
  };

  const renderScanner = () => {
    if (!showScanner) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-6 rounded-lg shadow-lg border-2 border-yellow-500">
          <div id="reader" className="w-64 h-64"></div>
        </div>
      </div>
    );
  };

  const renderQRCode = () => {
    if (!showQR) return null;

    const gameUrl = `${window.location.origin}/game/${gameId}`;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-8 rounded-lg shadow-lg border-2 border-yellow-500 text-center">
          <QRCodeSVG value={gameUrl} size={256} />
          <p className="mt-4 mb-4 text-white">Scan this QR code to join the game</p>
          <button 
            onClick={() => setShowQR(false)}
            className="w-full bg-blue-600 text-white py-2 rounded-full hover:bg-blue-700 transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const renderBingoCard = () => {
    if (!cardNumbers) return null;
    
    const handleNumberClick = (letter, number) => {
      const numberKey = `${letter}${number}`;
      if (displayedNumbers.includes(numberKey)) {
        setClickedNumbers(prev => 
          prev.includes(numberKey) 
            ? prev.filter(n => n !== numberKey)
            : [...prev, numberKey]
        );
      }
    };
  
    return (
      <div className="grid grid-cols-5 gap-1 bg-gradient-to-r from-blue-900 to-blue-800 p-2 rounded-lg shadow-lg">
        {['B', 'I', 'N', 'G', 'O'].map(letter => (
          <div key={letter} className="flex flex-col">
            <div className="text-center font-bold text-2xl mb-1 text-yellow-400">{letter}</div>
            {cardNumbers[letter].map((number, index) => {
              const numberKey = `${letter}${number}`;
              const isDisplayed = displayedNumbers.includes(numberKey);
              const isClicked = clickedNumbers.includes(numberKey);
              return (
                <div 
                  key={index} 
                  className={`text-center py-1 rounded mb-1 ${
                    isClicked 
                      ? 'bg-green-600 text-white' 
                      : 'bg-gray-800 text-white'
                  } ${isDisplayed ? 'cursor-pointer' : 'cursor-default'} transition duration-300`}
                  onClick={() => isDisplayed && handleNumberClick(letter, number)}
                >
                  {number === 'FREE' ? 'FREE' : number}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  const renderWinnerCard = () => {
    if (!winnerCardNumbers) return null;
    return (
      <div className="grid grid-cols-5 gap-1 bg-gradient-to-r from-yellow-600 to-gray-300 p-2 rounded-lg shadow-lg">
        {['B', 'I', 'N', 'G', 'O'].map(letter => (
          <div key={letter} className="flex flex-col">
            <div className="text-center font-bold text-2xl mb-1 text-white">{letter}</div>
            {winnerCardNumbers[letter].map((number, index) => (
              <div 
                key={index} 
                className={`text-center py-2 rounded mb-1 ${
                  displayedNumbers.includes(`${letter}${number}`) 
                    ? 'bg-yellow-300 text-black' 
                    : 'bg-gray-500 text-white'
                } transition duration-300`}
              >
                {number === 'FREE' ? 'FREE' : number}
              </div>
            ))}
          </div>
        ))}
        
      </div>
    );
  };

  const renderPaletteModal = () => {
    if (!showPaletteModal) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-6 rounded-lg shadow-lg border-2 border-yellow-500">
          <h3 className="text-xl font-bold mb-4 text-yellow-400">Enter New Palette Number</h3>
          <form onSubmit={handlePaletteSubmit}>
            <input
              type="number"
              value={newPaletteNumber}
              onChange={(e) => setNewPaletteNumber(e.target.value)}
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              placeholder="New Palette Number"
              required
            />
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
              >
                Join Game
              </button>
              <button
                type="button"
                onClick={() => setShowPaletteModal(false)}
                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const renderWinnerPopup = () => {
    if (gameDetails.status !== 'finished' ) return null;

    const bgColor = gameDetails.winnerCard === paletteNumber ? 'bg-green-600' : 'bg-red-600';
    return (
      <div className={`fixed inset-0 flex items-center justify-center z-50 ${bgColor} bg-opacity-90`}>
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-8 rounded-lg shadow-xl text-center border-4 border-yellow-500">
          <h2 className="text-3xl font-bold mb-4 text-yellow-400">
            {gameDetails.winnerCard === paletteNumber ? 'Congratulations!' : 'Game Over'}
          </h2>
          {gameDetails.winnerCard !== paletteNumber && (
            <h3 className="text-xl mb-4 text-white">The winner is Cartella: {gameDetails.winnerCard}</h3>
          )}
          {renderWinnerCard()}

          {latestGame && latestGame.status === 'waiting' && (
              <button 
                className="w-full bg-blue-600 text-white py-3 rounded-full text-xl font-bold hover:bg-blue-700 mt-6 transition duration-300 transform hover:scale-105 flex items-center justify-center"
                onClick={handleJoinLatestGame}
              >
                <Share2 className="mr-2" /> Join Again
              </button>
            )}
        </div>
      </div>
    );
  };

  if (!gameDetails || !cardNumbers || !audioFilesLoaded) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-purple-900 to-indigo-900">
        <div className="text-white text-2xl">Loading game...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 to-indigo-900 text-white p-4">
      <div className="max-w-4xl mx-auto">
        <ToastContainer position="top-center" autoClose={5000} />
        
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-4 rounded-lg shadow-lg mb-4 border-2 border-yellow-500">
          <h2 className="text-3xl font-bold mb-2 text-yellow-400">Bingo Game: {gameId}</h2>
          <p className="mb-1 flex items-center"><Coins className="mr-2 text-yellow-400" /> Your Card: {paletteNumber}</p>
          <p className="flex items-center"><Dice5 className="mr-2 text-yellow-400" /> Game Status: {gameDetails.status}</p>
          {countdown !== null && countdown > 0 && (
            <p className="flex items-center mt-2">
              <Clock className="mr-2 text-yellow-400" />
              Game starts in: 
              <span className="ml-2 text-2xl font-bold text-yellow-400">
                {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
              </span>
            </p>
          )}
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          {renderBingoCard()}
          
          <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-4 rounded-lg shadow-lg border-2 border-yellow-500">
            <h3 className="text-2xl font-bold mb-2 text-yellow-400">Called Numbers</h3>
            <div className="grid grid-cols-5 gap-2">
              {displayedNumbers.map((number, index) => (
                <div 
                  key={index} 
                  className={`text-center py-2 rounded ${
                    index === displayedNumbers.length - 1 
                      ? 'bg-blue-600 text-white' 
                      : 'bg-gray-800 text-white'
                  } transition duration-300`}
                >
                  {number}
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-4 rounded-lg shadow-lg mb-4 border-2 border-yellow-500">
          <h3 className="text-2xl font-bold mb-2 text-yellow-400">Last Called Number</h3>
          <div className="text-6xl font-bold text-center text-white">{lastCalledNumber || 'None'}</div>
        </div>
        
        {gameDetails.status === 'in-progress' && (
          <button 
            className="w-full bg-green-600 text-white py-3 rounded-full text-2xl font-bold hover:bg-green-700 mb-4 transition duration-300 transform hover:scale-105"
            onClick={handleClaimBingo}
          >
            BINGO!
          </button>
        )}
        
        {gameDetails.status === 'finished' && (
          <button 
            className="w-full bg-blue-600 text-white py-3 rounded-full text-2xl font-bold hover:bg-blue-700 mb-4 transition duration-300 transform hover:scale-105 flex items-center justify-center"
            onClick={handleScanNewGame}
          >
            <QrCode className="mr-2" /> Scan New Game
          </button>
        )}

        {error && (
          <p className="text-red-500 mt-4 bg-gray-800 p-2 rounded-lg">{error}</p>
        )}
        
        {renderWinnerPopup()}
        {renderScanner()}
        {renderQRCode()}
        {renderPaletteModal()}

        <audio ref={audioRef} />
        
        <div className="mt-4 text-center text-sm text-gray-400">
          <p>Audio: <Volume2 className="inline-block mr-1" size={16} /> {audioLanguage || 'Default'}</p>
        </div>
      </div>
      <div className="mt-4 flex justify-between items-center text-sm text-gray-400">
        <p>Audio: {audioLanguage || 'Default'}</p>
        {isDownloading ? (
          <p>Downloading audio files...</p>
        ) : (
          <button 
            onClick={toggleMute} 
            className="flex items-center bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {isMuted ? <VolumeX className="mr-2" size={16} /> : <Volume2 className="mr-2" size={16} />}
            {isMuted ? 'Unmute' : 'Mute'}
          </button>
        )}
      </div>
    </div>
  );
};

export default PlayerGame;