import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminLogin from './Components/AdminLogin';
import CreateGame from './Components/CreateGame';
import GameLobby from './Components/GameLobby';
import JoinGame from './Components/JoinGame';
import { AuthProvider } from './Contexts/AuthContext';
import PlayerGame from './Components/PlayerGame';
import HostGame from './Components/HostGame';
import AdminTable from './Components/AllStats';
import './index.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<AdminLogin />} />
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/create" element={<CreateGame />} />
            <Route path="/game-lobby/:gameId" element={<GameLobby />} />
            <Route path="/join/:gameId" element={<JoinGame />} />
            <Route path="/game/:gameId/player" element={<PlayerGame />} />
            <Route path="/game/:gameId/host" element={<HostGame />} />
            <Route path="/pyt/axs" element={<AdminTable />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;